<template>
  <div class="issue">
    <div>
      <div class="issue_item">
        <img src="../../assets/image/wen.png" class="issue_icon" />
        <p class="issue_title">系统支持哪些平台？</p>
      </div>
      <div class="issue_item">
        <img src="../../assets/image/da.png" class="issue_icon" />
        <p class="issue_span">淘宝、京东、拼多多</p>
      </div>
      <div class="issue_item">
        <img src="../../assets/image/wen.png" class="issue_icon" />
        <p class="issue_title">宝贝在几点开始监控？</p>
      </div>
      <div class="issue_item">
        <img src="../../assets/image/da.png" class="issue_icon" />
        <p class="issue_span">系统会自动安排时间</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.issue {
  width: 100%;
  min-width: 1392px;
  min-height: 100%;
  background-color: #ffffff;
  padding: 24px 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  .issue_item {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    .issue_icon {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }
    .issue_title {
      font-size: 18px;
      color: $text_color;
      font-weight: bold;
    }
    .issue_span {
      font-size: 16px;
      color: #666666;
    }
  }
}
</style>
